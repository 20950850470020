const getNodes = (state) => {
  const { from, to } = state.selection;
  const nodes = [];
  state.doc.nodesBetween(from, to, (node) => nodes.push(node));
  return nodes.filter((node) => node.type.name !== 'text');
};

const headingIsActive = (state, level) => {
  const node = getNodes(state)[0];
  if (!node) {
    return false;
  }
  return node.type.name === 'heading' && node.attrs.level === level;
};

const checkAlign = (state, align) => {
  const nodes = getNodes(state);
  if (nodes.length === 0) {
    return false;
  }
  const unique = [...new Set(nodes.map((item) => item.attrs.align))];

  return unique.length === 1 && unique[0] === align;
};

const getAlign = (state) => {
  const nodes = getNodes(state);
  if (nodes.length === 0) {
    return false;
  }
  const unique = [...new Set(nodes.map((item) => item.attrs.align))];

  return unique[0] ? unique[0] : null;
};

const toggleHeading = (editor, level) => {
  const align = getAlign(editor.state);

  if (headingIsActive(editor.state, level)) {
    editor.commands.paragraph({ align });
    return;
  }

  editor.commands.heading({ level, align });
};

export default (editor, context) => ({
  bold: {
    title: 'Bold',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Bold',
    command: editor.commands.bold,
    isActive: () => editor.focused && editor.isActive.bold(false),
  },
  italic: {
    title: 'Italic',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Italic',
    command: editor.commands.italic,
    isActive: () => editor.focused && editor.isActive.italic(false),
  },
  strike: {
    title: 'Strike',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Strikethrough',
    command: editor.commands.strike,
    isActive: () => editor.focused && editor.isActive.strike(false),
  },
  underline: {
    title: 'Underline',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Underline',
    command: editor.commands.underline,
    isActive: () => editor.focused && editor.isActive.underline(false),
  },
  h1: {
    title: 'Heading 1',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Header1',
    command: () => toggleHeading(editor, 1),
    isActive: () => editor.focused && headingIsActive(editor.state, 1),
  },
  h2: {
    title: 'Heading 2',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Header2',
    command: () => toggleHeading(editor, 2),
    isActive: () => editor.focused && headingIsActive(editor.state, 2),
  },
  h3: {
    title: 'Heading 3',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Header3',
    command: () => toggleHeading(editor, 3),
    isActive: () => editor.focused && headingIsActive(editor.state, 3),
  },
  ul: {
    title: 'Bullet list',
    icon: 'ms-Icon ms-Icon--m ms-Icon--BulletedList',
    command: editor.commands.bullet_list,
    isActive: () => editor.focused && editor.isActive.bullet_list(false),
  },
  ol: {
    title: 'Ordered list',
    icon: 'ms-Icon ms-Icon--m ms-Icon--NumberedList',
    command: editor.commands.ordered_list,
    isActive: () => editor.focused && editor.isActive.ordered_list(false),
  },
  image: {
    title: 'Insert image',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Photo2',
    command: () => {
      context.$buefy.dialog.prompt({
        message: 'Enter the url of your image here.',
        inputAttrs: {
          type: 'url',
        },
        confirmText: 'OK',
        trapFocus: true,
        onConfirm: (src) => editor.commands.image({ src }),
      });
    },
    isActive: () => editor.focused && editor.isActive.image(false),
  },
  link: {
    title: 'Insert link',
    icon: 'ms-Icon ms-Icon--m ms-Icon--AddLink',
    command: () => {
      context.$buefy.dialog.prompt({
        message: 'Enter the url of your link here.',
        inputAttrs: {
          type: 'url',
          required: false,
        },
        confirmText: 'OK',
        trapFocus: true,
        onConfirm: (href) => editor.commands.link({ href }),
      });
    },
    isActive: () => editor.focused && editor.isActive.link(false),
  },
  blockquote: {
    title: 'Block quote',
    icon: 'ms-Icon ms-Icon--m ms-Icon--RightDoubleQuote',
    command: editor.commands.blockquote,
    isActive: () => editor.focused && editor.isActive.blockquote(false),
  },
  codeblock: {
    title: 'Code block',
    icon: 'far fa-code',
    iconType: 'fa',
    command: editor.commands.code_block,
    isActive: () => editor.focused && editor.isActive.code_block(false),
  },
  tokens: {
    title: 'Tokens',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Code',
    command: (text) => editor.view.dispatch(editor.state.tr.insertText(text)),
  },
  hr: {
    title: 'Horizontal rule',
    icon: 'ms-Icon ms-Icon--m ms-Icon--ChromeMinimize',
    command: editor.commands.horizontal_rule,
    isActive: () => editor.focused && editor.isActive.horizontal_rule(false),
  },
  undo: {
    title: 'Undo',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Undo',
    command: editor.commands.undo,
    isActive: () => false,
  },
  redo: {
    title: 'Undo',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Redo',
    command: editor.commands.undo,
    isActive: () => false,
  },
  createTable: {
    title: 'Create table',
    icon: 'ms-Icon ms-Icon--m ms-Icon--Table',
    command: () => editor.commands
      .createTable({ rowsCount: 3, colsCount: 3, withHeaderRow: false }),
    isActive: () => false,
  },
  deleteTable: {
    title: 'Delete table',
    icon: 'ms-Icon ms-Icon--m ms-Icon--DeleteTable',
    command: editor.commands.deleteTable,
    isActive: () => false,
    visible: () => editor.isActive.table(),
  },
  addColumnBefore: {
    title: 'Insert column before',
    icon: 'ms-Icon ms-Icon--m ms-Icon--InsertColumnsLeft',
    command: editor.commands.addColumnBefore,
    isActive: () => false,
    visible: () => editor.isActive.table(),
  },
  addColumnAfter: {
    title: 'insert column after',
    icon: 'ms-Icon ms-Icon--m ms-Icon--InsertColumnsRight',
    command: editor.commands.addColumnAfter,
    isActive: () => false,
    visible: () => editor.isActive.table(),
  },
  deleteColumn: {
    title: 'Delete column',
    icon: 'ms-Icon ms-Icon--m ms-Icon--DeleteColumns',
    command: editor.commands.deleteColumn,
    isActive: () => false,
    visible: () => editor.isActive.table(),
  },
  addRowBefore: {
    title: 'Insert row before',
    icon: 'ms-Icon ms-Icon--m ms-Icon--InsertRowsAbove',
    command: editor.commands.addRowBefore,
    isActive: () => false,
    visible: () => editor.isActive.table(),
  },
  addRowAfter: {
    title: 'Insert row after',
    icon: 'ms-Icon ms-Icon--m ms-Icon--InsertRowsBelow',
    command: editor.commands.addRowAfter,
    isActive: () => false,
    visible: () => editor.isActive.table(),
  },
  deleteRow: {
    title: 'Delete row',
    icon: 'ms-Icon ms-Icon--m ms-Icon--DeleteRows',
    command: editor.commands.deleteRow,
    isActive: () => false,
    visible: () => editor.isActive.table(),
  },
  toggleCellMerge: {
    title: 'Delete table',
    icon: 'ms-Icon ms-Icon--m ms-Icon--DeleteTable',
    command: () => editor.commands.deleteTable(),
    isActive: () => false,
    visible: () => editor.isActive.table(),
  },
  alignLeft: {
    title: 'Left text align',
    icon: 'ms-Icon ms-Icon--m ms-Icon--AlignLeft',
    command: () => editor.commands.alignment({ align: 'left' }),
    isActive: () => editor.focused && checkAlign(editor.state, 'left'),
  },
  alignCenter: {
    title: 'Center text',
    icon: 'ms-Icon ms-Icon--m ms-Icon--AlignCenter',
    command: () => editor.commands.alignment({ align: 'center' }),
    isActive: () => editor.focused && checkAlign(editor.state, 'center'),
  },
  alignRight: {
    title: 'Right text align',
    icon: 'ms-Icon ms-Icon--m ms-Icon--AlignRight',
    command: () => editor.commands.alignment({ align: 'right' }),
    isActive: () => editor.focused && checkAlign(editor.state, 'right'),
  },
  alignJustify: {
    title: 'Justify text',
    icon: 'ms-Icon ms-Icon--m ms-Icon--AlignJustify',
    command: () => editor.commands.alignment({ textAlign: 'justify' }),
    isActive: () => editor.focused && checkAlign(editor.state, 'justify'),
  },
});
