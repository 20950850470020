const ALIGN_PATTERN = /(left|right|center|justify)/

export function getNodeAttrs(dom) {
    const {
        textAlign,
    } = dom.style
    const attrs = {}

    const align = dom.getAttribute('align') || textAlign || ''
    if (align && ALIGN_PATTERN.test(align)) {
        attrs.align = align
    }

    return attrs
}

export function getDOM(node, tag = 'p') {
    const {
        align,
    } = node.attrs

    const attrs = {}
    let style = ''

    if (align && align !== 'left') {
        style += `text-align: ${align};`
    }

    if (style) {
        attrs.style = style
    }

    return [tag, attrs, 0]
}
