<template>
  <div :class="$style.tokens">
    <slot
      :toggle="toggle"
      :is-open="isOpen"
    />

    <b-dropdown
      ref="dropdown"
      :position="position"
      :class="$style.dropdown"
      :style="{ top: `${top}px`, 'z-index': zIndex }"
      :max-height="maxHeight"
      scrollable
      :mobile-modal="false"
      :trigger-tabindex="-1"
      @active-change="change"
    >
      <template
        v-if="showTrigger"
        #trigger
      >
        <button
          type="button"
          :class="$style.icon"
          title="Tokens"
          tabindex="-1"
        >
          <font-awesome-icon :icon="['fal', 'brackets-curly']" />
        </button>
      </template>

      <b-dropdown-item
        custom
        :focusable="false"
        :class="$style.header"
      >
        <div :class="$style.search">
          <span :class="$style.search__icon">
            <font-awesome-icon :icon="['fal', 'magnifying-glass']" />
          </span>
          <input
            ref="tokenSearch"
            v-model="filter"
            placeholder="Search for tokens..."
            :class="$style.search__input"
            @focus="() => { currentTokenIndex = -1; }"
            @keydown.arrow-down.prevent="changeFocus"
            @keydown.tab.prevent="changeFocus"
          >
        </div>
      </b-dropdown-item>

      <b-dropdown-item
        custom
        :focusable="false"
        :class="$style.items"
      >
        <ul ref="tokenList" :class="$style['token-list']">
          <li
            v-for="(item, index) in filtredTokens"
            :key="item.name"
            :class="$style['token-list__item']"
          >
            <button
              type="button"
              :title="getName(item)"
              :class="$style['token-list__button']"
              @click="select(item)"
              @keydown.arrow-down.prevent="changeFocus"
              @keydown.arrow-up.prevent="changeFocus"
              @keydown.tab.prevent="changeFocus"
              @focus="() => { currentTokenIndex = index }"
            >
              <span :class="$style['token-list__inner']">
                {{ getName(item) }}
              </span>
            </button>
          </li>
          <li
            v-if="!filtredTokens.length"
            :class="$style['token-list__empty']"
          >
            No tokens found...
          </li>
        </ul>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useTokensStore } from '@/stores/index.js';
import { searchWith } from '@/utils/index.js';

export default {
  name: 'Tokens',
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    position: {
      type: [String, null],
      default: 'is-bottom-left',
    },
    top: {
      type: Number,
      default: 8,
    },
    zIndex: {
      type: [Number, null],
      default: null,
    },
    showTrigger: {
      type: Boolean,
      default: true,
    },
    maxHeight: {
      type: Number,
      default: 222,
    },
  },
  data() {
    return {
      isOpen: false,
      filter: '',
      currentTokenIndex: -1,
    };
  },
  computed: {
    ...mapState(useTokensStore, ['sortedTokens']),

    filtredTokens() {
      const searchByLabel = ({ label }) => ({ label: `{{${label}}}` });

      return searchWith(this.filter)(searchByLabel)(this.sortedTokens);
    },
  },
  mounted() {
    const [el] = this.$el.querySelectorAll('input, textarea');
    this.innerControl = el;
  },
  methods: {
    getName({ label }) {
      return `{{${label}}}`;
    },
    select(item) {
      if (!this.innerControl) {
        return this.$emit('input', this.getName(item));
      }

      const cursorPosition = this.innerControl.selectionStart;
      const value = this.value ? this.value : '';

      return Array.isArray(value)
        ? this.$emit('input', [...value, { key: '', value: this.getName(item) }])
        : this.$emit(
          'input',
          value.slice(0, cursorPosition) + this.getName(item) + value.slice(cursorPosition),
        );
    },
    toggle() {
      this.$refs.dropdown.toggle();
    },
    change(value) {
      this.isOpen = value;
      if (value) {
        this.$nextTick(() => { this.$refs.tokenSearch.focus(); });
      }
    },

    changeFocus(e) {
      const maxValue = this.filtredTokens.length - 1;
      const isUp = e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey);
      const isDown = e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey);

      if (isDown) {
        if (this.currentTokenIndex >= maxValue) return;
        this.$refs.tokenList.childNodes[this.currentTokenIndex + 1].querySelector('button').focus();
      }

      if (isUp) {
        if (this.currentTokenIndex <= 0) {
          this.$refs.tokenSearch.focus();
          return;
        }
        this.$refs.tokenList.childNodes[this.currentTokenIndex - 1].querySelector('button').focus();
      }
    },
  },
};
</script>

<style lang="scss" module>
.tokens {
  position: relative;

  .icon {
    padding: 0 10px;
    border: none;
    background-color: transparent;
    font-size: 1em;
    color: var(--color-secondary);
    cursor: pointer;
  }

  .dropdown {
    position: absolute;
    right: 0;

    :global(.dropdown-content) {
      width: 300px;
      padding-bottom: 10px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    :global(.dropdown-menu) {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .header {
      padding: 10px 8px 3px;
      font-weight: 700;
    }

    .items {
      padding-top: 3px;
      padding-left: 8px;
      padding-right: 8px;
      font-weight: 400;
      overflow: auto;
      display: flex;
      flex-direction: column;

      &::-webkit-scrollbar {
        width: 12px;
        height: 12px;;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border: 3px solid #fff;
        border-radius: 10px;
        background-color: hsl(0, 0%, 86%);
      }
    }
  }

  .search {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid hsl(0, 0%, 86%);
    padding: 7px 8px 8px;
    height: 30px;

    &:focus-within {
      box-shadow: 0 0 1px 1px rgba(134, 142, 150, .25);
    }

    &__icon {
      position: absolute;
      left: 9px;
      font-size: 14px;
    }

    &__input {
      width: 100%;
      font-size: 14px;
      padding-left: 24px;

      border: none;

      &:hover,
      &:focus {
        outline: none;
      }
    }
  }

  .token-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    &__item {
      position: relative;
      height: 32px;
      padding: 0 10px;
      overflow: hidden;

      &:hover,
      &:focus-within {
        background-color: #f5f5f5;
      }
    }

    &__button {
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;
      font-size: inherit;
      color: inherit;
      text-align: start;
      display: flex;
      align-items:center;
      cursor: pointer;

      &:focus-visible {
        outline: none;
      }
    }

    &__inner {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__empty {
      padding: 4px 4px 0;
      font-style: italic;
    }
  }

  // TODO: in future think how to move this slots styles outside tokens component
  :global(a.button) {
    margin-bottom: 2px;
    border: none;
    background-color: transparent;

    :global(.icon) {
      background-color: transparent;
    }
  }

  :global(a.button.is-primary) {
    background-color: var(--current-color);

    :global(.icon) {
      color: var(--color-white);
    }
  }

  :global(.tags-input) {
    padding-right: 40px;
  }

  :global(textarea.input) {
    padding-right: 40px;
    resize: none;

    // for firefox
    scrollbar-width: thin;

    &::-webkit-scrollbar {
     width: 7px;
     cursor: pointer;

     &-track {
       background-color: #fff;
      }

      &-thumb {
      border: 1px solid #fff;
      border-radius: 5px;
      background-color: hsl(0deg, 0%, 86%);
      transition: background-color 200ms linear;
     }
    }

    &:hover::-webkit-scrollbar-thumb,
    &:focus::-webkit-scrollbar-thumb {
      background-color: hsl(0deg, 0%, 50%);
    }
  }

  :global(input.input) {
    padding-right: 40px;
  }
}
</style>
