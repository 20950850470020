<template>
  <div class="rich-text-editor">
    <editor-menu-bar
      ref="menuBar"
      :editor="editor"
      class="editor-menu-bar panel-heading"
    >
      <div class="buttons is-marginless">
        <div class="buttons is-marginless editor-menu-bar__buttons">
          <template v-for="(button, index) in buttons">
            <Tokens
              v-if="button.title === 'Tokens'"
              :key="index + 'tokens'"
              :show-trigger="false"
              :position="null"
              @input="button.command"
            >
              <template #default="{ toggle, isOpen }">
                <a
                  v-show="currentMode !== 'source'"
                  :key="index"
                  :ref="index"
                  class="button"
                  :class="{ 'is-primary': isOpen }"
                  :title="button.title"
                  :disabled="button.disabled ? button.disabled() : false"
                  @click="toggle"
                >
                  <span class="icon is-small">
                    <i :class="button.icon" />
                  </span>
                </a>
              </template>
            </Tokens>

            <a
              v-else
              v-show="currentMode !== 'source'"
              :key="index"
              :ref="index"
              :title="button.title"
              :disabled="button.disabled ? button.disabled() : false"
              :class="{ 'is-primary': button.isActive() }"
              class="button"
              @click="button.command"
            >
              <font-awesome-icon
                v-if="button.iconType === 'fa'"
                :icon="['fal', 'code']"
              />

              <span
                v-else
                class="icon is-small"
              >
                <i :class="button.icon" />
              </span>
            </a>
          </template>

          <a
            v-if="editSource"
            title="Edit source code"
            :class="{ 'is-primary': currentMode === 'source' }"
            class="button"
            @click="changeMode"
          >
            <span class="icon is-small">
              <i class="ms-Icon ms-Icon--m ms-Icon--FileCode" />
            </span>
          </a>
        </div>
      </div>
    </editor-menu-bar>

    <div
      class="content panel-block is-block test"
      :style="formatConfig.minHeight ? `min-height: ${formatConfig.minHeight}px` : ''"
    >
      <div v-show="currentMode === 'html'">
        <editor-content
          :editor="editor"
          class="editor__content"
        />
      </div>

      <div v-show="currentMode === 'source'">
        <textarea />
      </div>
    </div>
  </div>
</template>

<script>
import CodeMirror from 'codemirror/lib/codemirror.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  Image,
} from 'tiptap-extensions';
import { html as beautify } from 'js-beautify';
import Tokens from '@/components/tokens.vue';
import Paragraph from './nodes/paragraph.js';
import Heading from './nodes/heading.js';
import Align from './extensions/align.js';
import configureButtons from './buttons-configuration.js';

export default {
  name: 'RichTextEditor',
  components: {
    EditorContent,
    EditorMenuBar,
    Tokens,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      skipEmit: false,
      currentMode: 'html',
      editor: null,
      sourceEditor: null,
    };
  },
  computed: {
    formatConfig() {
      return this.config
        ? this.config
        : {
          editSource: true,
          buttons: [
            'tokens',
            'bold',
            'italic',
            'strike',
            'underline',
            'h1',
            'h2',
            'h3',
            'ul',
            'ol',
            'image',
            'link',
            'blockquote',
            'codeblock',
            'createTable',
            'hr',
            'deleteTable',
            'addColumnBefore',
            'addColumnAfter',
            'deleteColumn',
            'addRowBefore',
            'addRowAfter',
            'deleteRow',
            'toggleCellMerge',
          ],
        };
    },
    buttons() {
      return this.editor
        ? this.formatConfig.buttons
          .map((b) => this.buttonsConfiguration[b])
          .filter((b) => (b.visible ? b.visible() : true))
        : [];
    },
    editSource() {
      return this.formatConfig.editSource ? this.formatConfig.editSource : false;
    },
  },
  activated() {
    this.editor.setContent(this.value);
  },
  mounted() {
    this.sourceEditor = CodeMirror.fromTextArea(this.$el.querySelector('textarea'), {
      mode: 'htmlmixed',
      indentUnit: 4,
      tabSize: 4,
      indentWithTabs: false,
      lineNumbers: false,
      autofocus: true,
      lineWrapping: true,
    });

    this.sourceEditor.on('change', (cm) => {
      if (!this.skipEmit) {
        this.$emit('input', cm.getValue());
      }
    });

    this.editor = new Editor({
      content: this.value,
      onUpdate: ({ getHTML }) => {
        if (!this.skipEmit) {
          this.$emit('input', beautify(getHTML()));
        }
      },
      extensions: [
        new Paragraph(),
        new Align(),
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new Table({
          resizable: true,
        }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new Image(),
      ],
    });

    this.buttonsConfiguration = configureButtons(this.editor, this);
  },
  methods: {
    changeMode() {
      this.skipEmit = true;
      this.currentMode = this.currentMode === 'html' ? 'source' : 'html';

      if (this.currentMode === 'html') {
        this.editor.setContent(this.value, true);
      } else {
        this.sourceEditor.setValue(this.value);

        setTimeout(() => this.sourceEditor.refresh(), 1);
      }

      this.skipEmit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.rich-text-editor {
  border-radius: 4px;
  border: 1px solid rgb(219, 219, 219);
  .editor-menu-bar {
    border-radius: 0;
    top: 64px;
    z-index: 10;
    &__buttons {
      display: flex;
      column-gap: 0.6rem;
      row-gap: 2px;
      a {
        color: inherit;
        margin: 0 !important;
        border: none;
        text-decoration: none;
      }
    }
  }
}
</style>

<style lang="scss">
@import '~codemirror/lib/codemirror.css';
.dialog {
  z-index: 999;

  .button {
    font-weight: 400 !important;
  }
}

.ProseMirror {
  outline: none;
  min-height: 300px;
}

.CodeMirror {
  border: 1px solid #eee;
  height: 300px;
}

.CodeMirror-wrap pre {
  word-break: break-word;
}

* {
  box-sizing: border-box;
}

.buttons > .buttons > a.button:not(.is-primary) {
  border: none;
  background-color: transparent;
}

.buttons > .buttons:not(:last-child) {
  padding-right: 2px;
}

.buttons > .buttons > .button {
  margin-bottom: 2px;
}

.editor {
  &__content {
    overflow-wrap: break-word;
    min-height: 300px;
    word-break: break-word;
    word-wrap: break-word;

    * {
      caret-color: currentColor;
    }

    table {
      table-layout: fixed;
      overflow: hidden;
      margin: 0;
      border-collapse: collapse;
      width: 100%;

      td,
      th {
        position: relative;
        vertical-align: top;
        border: 2px solid #dbdbdb !important;
        padding: 3px 5px;
        min-width: 1em;
        box-sizing: border-box;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: 700;
        text-align: left;
      }

      .selectedCell::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: 0;
        z-index: 20;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
      }
    }

    .tableWrapper {
      overflow-x: auto;
      margin: 1em 0;
    }

    .resize-cursor {
      cursor: col-resize;
    }
  }
}
</style>
