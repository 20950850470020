import { Node } from 'tiptap'
import { setBlockType, textblockTypeInputRule, toggleBlockType } from 'tiptap-commands'
import { getNodeAttrs, getDOM } from '../utils';

function getAttrs(dom) {
    return {
        ...getNodeAttrs(dom),
        level: Number(dom.nodeName[1] || 1),
    }
};

export default class Heading extends Node {
    get name() {
        return 'heading'
    }

    get defaultOptions() {
        return {
            levels: [1, 2, 3, 4, 5, 6],
        }
    }

    get schema() {
        return {
            attrs: {
                level: {
                    default: 1,
                },
                align: { default: null },
            },
            content: 'inline*',
            group: 'block',
            defining: true,
            draggable: false,
            parseDOM: this.options.levels
                .map(level => ({
                    tag: `h${level}`,
                    getAttrs,
                })),
            toDOM: node => getDOM(node, `h${node.attrs.level}`),
        }
    }

    commands({ type, schema }) {
        return attrs => toggleBlockType(type, schema.nodes.paragraph, attrs)
    }

    keys({ type }) {
        return this.options.levels.reduce((items, level) => ({
            ...items,
            ...{
                [`Shift-Ctrl-${level}`]: setBlockType(type, { level }),
            },
        }), {})
    }

    inputRules({ type }) {
        return this.options.levels.map(level => textblockTypeInputRule(
            new RegExp(`^(#{1,${level}})\\s$`),
            type,
            () => ({ level }),
        ))
    }
}
