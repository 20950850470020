import { setBlockType } from 'tiptap-commands';
import { Node } from 'tiptap';
import { getNodeAttrs, getDOM } from '../utils';

export default class Paragraph extends Node {
    get name() {
        return 'paragraph';
    }

    get schema() {
        return {
            attrs: {
                align: {
                    default: null,
                }
            },
            content: 'inline*',
            group: 'block',
            draggable: false,
            parseDOM: [
                {
                    tag: 'p',
                    getAttrs: getNodeAttrs,
                }
            ],
            toDOM: node => getDOM(node, 'p'),
        };
    }

    commands({ type }) {
        return attrs => setBlockType(type, attrs);
    }
}
